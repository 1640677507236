import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavModal = () => {
  const [showOne, setShowOne] = useState(false);
  const handleCloseOne = () => setShowOne(false);
  const handleShowOne = () => setShowOne(true);

  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <button
            className="btn btn-primary btn-md "
            style={{ float: "right" }}
            onClick={handleShowOne}
          >
            <FaPlus size={18} /> Add
          </button>
        </div>
      </div>

      <Modal show={showOne} onHide={handleCloseOne}>
        <Modal.Header id="example-modal-sizes-title-lg" className="d-block">
          <Modal.Title></Modal.Title>
          <div className="row">
            <div className="col-md-6">
              <h4 className="mt-3">CREATE NEW PROSPECTS</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-2">
          <div className="container">
            <div className="row">
              <div className="col-md-12 my-2">
                <Link
                  to={"/Addlender"}
                  onClick={() => {
                    handleCloseOne();
                    handleCloseTwo();
                  }}
                >
                  <Button variant="secondary" className="w-100">
                    Input new prospect
                  </Button>
                </Link>
              </div>
              <div className="col-md-12 my-2">
                <Button variant="secondary" className="w-100">
                  Issue a Pre-Qualified letter
                </Button>
              </div>
              <div className="col-md-6 my-2">
                <Button variant="secondary" className="w-100">
                  Import 3.2 FNM file
                </Button>
              </div>
              <div className="col-md-6 my-2">
                <Button variant="secondary" className="w-100">
                  Import 3.4 XML file
                </Button>
              </div>
              <div className="col-md-12">
                <h4 className="text-center">OR</h4>
              </div>
              <div className="col-md-12 my-2">
                <Button
                  variant="secondary"
                  className="w-100"
                  onClick={() => {
                    handleShowTwo();
                    handleCloseOne();
                  }}
                >
                  Invite borrowers
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseOne}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleCloseOne}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTwo} onHide={handleCloseTwo}>
        <Modal.Header id="example-modal-sizes-title-lg" className="d-block">
          <Modal.Title></Modal.Title>
          <div className="row">
            <div className="col-md-6">
              <h4 className="mt-3">Add Loan</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-2">
                <div className="form-group-new">
                  <input
                    className="form-control-new rounded-0 text-capitalize"
                    type="text"
                    placeholder=" "
                    required
                  />
                  <label htmlFor="inputheight1" className="label-new">
                    Frist Name
                  </label>
                </div>
                {/* <label>Frist Name:</label>
                <input className="form-control" type="email" /> */}
              </div>
              <div className="col-md-6 my-2">
                <div className="form-group-new">
                  <input
                    className="form-control-new rounded-0 text-capitalize"
                    type="text"
                    placeholder=" "
                    required
                  />
                  <label htmlFor="inputheight1" className="label-new">
                    Last Name
                  </label>
                </div>
                {/* <label>Last Name:</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" />
                </div> */}
              </div>
              <div className="col-md-6 my-2">
                <div className="form-group-new">
                  <input
                    className="form-control-new rounded-0 text-capitalize"
                    type="text"
                    placeholder=" "
                    required
                  />
                  <label htmlFor="inputheight1" className="label-new">
                    Email
                  </label>
                </div>
                {/* <label>Email:</label>
                <input className="form-control" type="email" /> */}
              </div>
              <div className="col-md-6 my-2">
                <div className="form-group-new">
                  <input
                    className="form-control-new rounded-0 text-capitalize"
                    type="text"
                    placeholder=" "
                    required
                  />
                  <label htmlFor="inputheight1" className="label-new">
                    Phone Number
                  </label>
                </div>
                {/* <label>Phone Number:</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" />
                </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseTwo}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleCloseTwo}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavModal;
