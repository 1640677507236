/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import "./leb.css";
import { useSelector } from "react-redux";
import { FaPlus, FaUserEdit } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { MdDelete, MdOutlinePending } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ImCross } from "react-icons/im";
import { FcApproval } from "react-icons/fc";
import BtnLoader from "./BtnLoader";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div className="d-flex">
      <input
        className="form-control input-search "
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({
  filter,
  setFilter,
  dataSingle,
  setDataSingle,
  GetAllLeb,
}) => {
  useEffect(() => {
    if (dataSingle) {
      setProjectName(dataSingle[0]?.page_name);
      setProjectPage(dataSingle[0]?.project_page_status);
      setProjectAddress(dataSingle[0]?.page_address);
      setInputs(dataSingle[0]?.des);
      setShow(true);
    }
  }, [dataSingle]);

  const token = useSelector((state) => state.auth.auth.idToken);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setInputs([]);
    setProjectName("");
    setProjectAddress("");
    setProjectPage("");
    setDataSingle(false);
  };
  const handleShow = () => setShow(true);

  const [inputs, setInputs] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectPage, setProjectPage] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);

  const handleAddInput = () => {
    setInputs([...inputs, { id: inputs.length + 1, description: "" }]);
  };

  const handleRemoveInput = (id) => {
    setInputs(inputs.filter((input) => input.id !== id));
  };

  const handleInputChange = (id, newValue) => {
    setInputs(
      inputs.map((input) =>
        input.id === id ? { ...input, description: newValue } : input
      )
    );
  };

  const handleSubmit = () => {
    setBtnLoader(true);
    const formData = new FormData();
    formData.append("page_name", projectName);
    formData.append("project_page", null);
    formData.append("project_page_status", projectPage);
    formData.append("page_address", projectAddress);
    inputs?.map((e, i) => formData.append(`description[${i}]`, e?.description));
    axios
      .post(
        "https://bankerbrokerapi.dev-mn.xyz/api/store/lab/record",
        formData,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "responsee");
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "success",
          title: "LEB Add Successfully",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        GetAllLeb();
        handleClose();
        setBtnLoader(false);
      })
      .catch((err) => {
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: err?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setBtnLoader(false);
      });
  };

  const handleSubmitEdit = () => {
    setBtnLoader(true);
    const formData = new FormData();
    formData.append("page_name", projectName);
    formData.append("project_page", null);
    formData.append("project_page_status", projectPage);
    formData.append("page_address", projectAddress);
    inputs?.map((e, i) => formData.append(`description[${i}]`, e?.description));
    formData.append("id", dataSingle[0]?.id);
    axios
      .post(
        "https://bankerbrokerapi.dev-mn.xyz/api/update/lab/record",
        formData,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "responsee");
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "success",
          title: "LEB Edit Successfully",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        handleClose();
        GetAllLeb();
        setBtnLoader(false);
      })
      .catch((err) => {
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: err?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setBtnLoader(false);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 ">
          <input
            className="ml-2 input-search form-control w-50 "
            value={filter || ""}
            placeholder="Search"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <button
            className="btn btn-primary btn-md "
            style={{ float: "right" }}
            onClick={handleShow}
          >
            <FaPlus size={18} /> Add
          </button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header id="example-modal-sizes-title-lg" className="d-block">
          <Modal.Title></Modal.Title>
          <div className="row">
            <div className="col-md-6">
              <h4 className="mt-3">Add LEB</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 my-2">
                <label>Project Name:</label>
                <input
                  className="form-control"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="col-md-12 my-2">
                <label>Project Page:</label>
                <div className="input-group">
                  <select
                    class="form-select"
                    style={{ borderRadius: "1em" }}
                    aria-label="Trang Nguyen"
                    value={projectPage}
                    onChange={(e) => setProjectPage(e.target.value)}
                  >
                    <option disabled>Project Page</option>
                    <option value="New">New</option>
                    <option value="Existing">Existing</option>
                  </select>
                </div>
                <div className="col-md-12 my-2">
                  <label>Page Address:</label>
                  <input
                    className="form-control"
                    value={projectAddress}
                    onChange={(e) => setProjectAddress(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="col-md-12 my-2">
                  <label>Auto Number List Of Project Details:</label>
                </div>
                <div className="col-md-12 d-flex">
                  <button
                    className="btn btn-primary btn-md"
                    style={{ float: "left" }}
                    onClick={handleAddInput}
                  >
                    <FaPlus size={18} /> Add
                  </button>
                </div>
                <div className="row">
                  {inputs?.map((input) => (
                    <div key={input?.id} className="col-md-6 my-2">
                      <label>Description:</label>
                      <input
                        type="text"
                        className="form-control my-2"
                        value={input?.description}
                        onChange={(e) =>
                          handleInputChange(input?.id, e.target.value)
                        }
                      />
                      <button
                        className="btn btn-danger btn-md"
                        onClick={() => handleRemoveInput(input?.id)}
                      >
                        <MdDelete size={18} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          {console.log(dataSingle, "dataSingledataSingle")}
          <Button
            variant="secondary"
            onClick={dataSingle ? handleSubmitEdit : handleSubmit}
          >
            {btnLoader ? (
              <>
                Submit <BtnLoader />
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const LEB = () => {
  //   Filter: ColumnFilter,

  const COLUMNS = [
    {
      Header: "Page Name",
      Footer: "page_name",
      accessor: "page_name",
    },
    {
      Header: "Project Page",
      Footer: "project_page_status",
      accessor: "project_page_status",
    },
    {
      Header: "Page Address",
      Footer: "page_address",
      accessor: `page_address`,
    },
    {
      Header: "Status",
      Footer: "is_pending",
      accessor: "is_pending",
      Cell: ({ value }) => {
        return (
          <>
            <div className="d-flex">
              {value == 1 ? (
                <button
                  className="btn btn-primary btn-md"
                  style={{ float: "left" }}
                >
                  <MdOutlinePending size={16} /> Pending
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-md"
                  style={{ float: "left" }}
                >
                  <FcApproval size={18} /> Approved
                </button>
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Description",
      Footer: "des",
      accessor: "des",
      Cell: ({ value }) => {
        return (
          <>
            <div className="d-flex flex-column">
              {value?.map((desc, i) => (
                <p>{i + " " + desc?.description}</p>
              ))}
            </div>
          </>
        );
      },
    },
    {
      Header: "Action",
      Footer: "id",
      accessor: "id",
      Cell: ({ value }) => {
        return (
          <>
            <div className="d-flex">
              <button
                onClick={() => handlerEdit(value)}
                className="btn btn-success mx-2 shadow btn-xs sharp"
              >
                <FaUserEdit />
              </button>
              <button
                onClick={() => handlerDelete(value)}
                className="btn btn-danger shadow btn-xs sharp"
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </>
        );
      },
      Filter: ColumnFilter,
    },
  ];
  const [columns, setColums] = useState(COLUMNS);
  const [data, setData] = useState([]);
  const [dataSingle, setDataSingle] = useState(false);

  const GetAllLeb = () => {
    axios
      .get("https://bankerbrokerapi.dev-mn.xyz/api/get/all/lab/records", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res, "responsee");
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlerEdit = (id) => {
    axios
      .get(
        `https://bankerbrokerapi.dev-mn.xyz/api/get/single/lab/record/${id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDataSingle(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlerDelete = (id) => {
    console.log(id);
    axios
      .delete(`https://bankerbrokerapi.dev-mn.xyz/api/del/lab/record/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "success",
          title: "LEB Delete Successfully",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        GetAllLeb();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetAllLeb();
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [getcompany, setGetCompany] = useState([]);
  const [loader, setLoader] = useState(false);
  const token = useSelector((state) => state.auth.auth.idToken);
  let Details = localStorage.getItem("userDetail");
  Details = JSON.parse(Details);
  const [rowdata, setRowget] = useState("");
  const [type, setType] = useState("");
  const loansdata = [
    { title: "Active", no: 4 },
    { title: "Purchase", no: 4 },
    { title: "Refinance", no: 4 },
    { title: "InCompelete Pre-approval", no: 4 },
    { title: "Approved Pre-approval", no: 4 },
    { title: "New", no: 4 },
    { title: "Incomplete", no: 4 },
    { title: "Inprogress", no: 4 },
    { title: "Active Past-due flags ", no: 4 },
    { title: "Inactive Past-due flags ", no: 4 },
  ];
  const path = window.location.pathname;
  const newpath = path.split("/");
  console.log(newpath, "path");
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{newpath[1]}</h4>
        </div>
        {newpath[1] === "LEB" ? (
          <div className="px-4 mt-3">
            {loansdata.map((e, i) => (
              <h5 className="btn btn-outline-primary mx-2 ">
                {e.title} - {e.no + i + 1}
              </h5>
            ))}
          </div>
        ) : null}
        <div className="card-body">
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            dataSingle={dataSingle}
            setDataSingle={setDataSingle}
            GetAllLeb={GetAllLeb}
          />
          <div className="table-responsive">
            <table {...getTableProps()} className="table  display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LEB;
