import React from 'react'

const Additional_city = ({handleadditionalcityChange,additional_city,borrowerdata}) => {
    const handleChange = (e) => {
        handleadditionalcityChange(e.target.value); // Update the selected city
      };
      const californiaCities = [
        "Los Angeles",
        "San Francisco",
        "San Diego",
        "San Jose",
        "Fresno",
        "Sacramento",
        "Long Beach",
        "Oakland",
        "Bakersfield",
        "Anaheim",
        "Santa Ana",
        "Riverside",
        "Stockton",
        "Chula Vista",
        "Irvine",
        "Fremont",
        "San Bernardino",
        "Modesto",
        "Fontana",
        "Oxnard",
        "Moreno Valley",
        "Huntington Beach",
        "Glendale",
        "Santa Clarita",
        "Garden Grove",
        "Oceanside",
        "Rancho Cucamonga",
        "Ontario",
        "Lancaster",
        "Elk Grove",
        "Corona",
        "Palmdale",
        "Salinas",
        "Hayward",
        "Pomona",
        "Escondido",
        "Sunnyvale",
        "Torrance",
        "Pasadena",
        "Fullerton",
        "Orange",
        "Roseville",
        "Visalia",
        "Concord",
        "Thousand Oaks",
        "Santa Clara",
        "Simi Valley",
        "Victorville",
        "Vallejo",
        "Berkeley",
        "El Monte",
        "Downey",
        "Costa Mesa",
        "Inglewood",
        "Carlsbad",
        "San Buenaventura (Ventura)",
        "Fairfield",
        "West Covina",
        "Murrieta",
        "Richmond",
        "Norwalk",
        "Antioch",
        "Temecula",
        "Burbank",
        "Daly City",
        "Rialto",
        "Santa Maria",
        "El Cajon",
        "San Mateo",
        "Compton",
        "Clovis",
        "South Gate",
        "Vista",
        "Mission Viejo",
        "Vacaville",
        "Carson",
        "Hesperia",
        "Redding",
        "Santa Monica",
        "Westminster",
        "Santa Barbara",
        "Chico",
        "Whittier",
        "Newport Beach",
        "San Leandro",
        "Hawthorne",
        "San Marcos",
        "Citrus Heights",
        "Alhambra",
        "Tracy",
        "Livermore",
        "Buena Park",
        " Lakewood",
        "Merced",
        "Hemet",
        "Chino",
        "Menifee",
        "Lake Forest",
        "Napa",
        "Redwood City",
        "Bellflower",
        "Indio",
        "Tustin",
        "Baldwin Park",
        "Chino Hills",
        "Mountain View",
        "Alameda",
        "Upland",
        "Folsom",
        "San Ramon",
        "Pleasanton",
        "Lynwood",
        "Union City",
        "Apple Valley",
        "Redlands",
        "Turlock",
        "Perris",
        "Manteca",
        "Milpitas",
        "Redondo Beach",
        "Davis",
        "Camarillo",
        "Yuba City",
        "South San Francisco",
        "Walnut Creek",
        "Pittsburg",
        "Palmdale",
        "Laguna Niguel",
        "San Clemente",
        "Pico Rivera",
        "Montebello",
        "Lodi",
        "Madera",
        "Santa Cruz",
        "La Habra",
        "Encinitas",
        "Monterey Park",
        "Tulare",
        "Cupertino",
        "Gardena",
        "National City",
        "Rocklin",
        "Petaluma",
        "Huntington Park",
        "San Rafael",
        "La Mesa",
        "Arcadia",
        "Fountain Valley",
        "Diamond Bar",
        "Woodland",
        "Santee",
        "Lake Elsinore",
        "Porterville",
        "Paramount",
        "Eastvale",
        "Rosemead",
        "Hanford",
        "Highland",
        "Brentwood",
        "Novato",
        "Colton",
        "Cathedral City",
        "Delano",
        "Yucaipa",
        "Watsonville",
        "Placentia",
        "Glendora",
        "Palm Desert",
        "Cerritos",
        "Rancho Santa Margarita",
        "Gilroy",
        "La Mirada",
        "Cypress",
        "Dublin",
        "Palm Springs",
        "Aliso Viejo",
        "Poway",
        "Rancho Palos Verdes",
        "Moorpark",
        "Foster City",
        "Pleasant Hill",
        "San Bruno",
        "Beaumont",
        "San Gabriel",
        "Bell Gardens",
        "Los Gatos",
        "Menlo Park",
        "West Sacramento",
        "Temple City",
        "Wildomar",
        "Saratoga",
        "Imperial Beach",
        "Seaside",
        "La Quinta",
        "Danville",
        "El Centro",
        "San Jacinto",
        "Rohnert Park",
        "Brea",
        "San Juan Capistrano",
        "La Puente",
        "Campbell",
        "Rancho Mirage",
        "Hollister",
        "Bell",
        "El Paso de Robles (Paso Robles)",
        "Lompoc",
        "Calexico",
        "Socorro",
        "Watsonville",
        "Fountain Hills",
        "Sahuarita",
        "Sedona",
        "Show Low",
        "Apache Junction",
        "Florence",
        "El Mirage",
        "Queen Creek",
        "San Luis",
        "Douglas",
        "Payson",
        "Paradise Valley",
        "Coolidge",
        "Cottonwood",
        "Camp Verde",
        "Chino Valley",
        "Winslow",
        "Safford",
        "Globe",
        "Bisbee",
        "Willcox",
        "Snowflake",
        "Taylor",
        "Thatcher",
        "Clarkdale",
        "Carefree",
        "Clifton",
        "Parker",
        "Wellton",
        "Superior",
        "Pima",
        "Star Valley",
        "Kearny",
        "Gila Bend",
        "Miami",
        "Maricopa",
        "Tusayan",
        "Winkelman",
        "Hayden",
        "Tonto Basin",
        "Young",
        "Jerome",
        "Quartzsite",
        "Stanfield",
        "Duncan",
        "Mammoth",
        "Fredonia",
        "Patagonia",
        "Tombstone",
        "Benson",
        "Springerville",
        "St. Johns",
        "Carefree",
        "Clifton",
        "Parker",
        "Wellton",
        "Super",
      ]
  return (
    <>
    <select className="form-select rounded-0" style={{padding:"10px"}} aria-label="Default select example" onChange={handleChange}>
                    <option value="">{additional_city || borrowerdata?.additional_city || "Select a City"}</option>
                    {californiaCities.map((city, index) => (
                      <option key={index} value={city}>{city}</option>
                    ))}
                  </select>
    </>
  )
}

export default Additional_city