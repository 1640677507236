import React from 'react'

export const Createnewcom = () => {
    return (
        <>
            <div class="row align-items-start mt-3">
                <div class="col-lg-4">
                    <label for="inputPassword" className=" col-form-label text-capitalize" style={{ fontSize: "13px", letterSpacing: "1px" }}>Commission plane<span className='text-muted' style={{ fontSize: "13px" }}>(optional)</span></label>
                </div>
                <div class="col-lg-8">
                    <div class="input-group">
                        <input type="text" class="form-control rounded-0" placeholder="1099_NEW50bps" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
            </div>
            <div class="row align-items-start mt-3">
                <div class="col-lg-4">
                    <label for="inputPassword" className=" col-form-label text-capitalize" style={{ fontSize: "13px", letterSpacing: "1px" }}>formula<span className='text-muted' style={{ fontSize: "13px" }}>(optional)</span></label>
                </div>
                <div class="col-lg-8">
                    <span className='text-success' style={{ fontSize: "13px" }}>Y=</span>
                    <span className='text-primary' style={{ fontSize: "13px" }}>loan amount</span>
                    <span className='text-danger' style={{ fontSize: "13px" }}> x Originator percentage</span>
                    <span className='text-primary' style={{ fontSize: "13px" }}> x (1-Companyganerted percentage);</span>
                    <span className='text-success' style={{ fontSize: "13px" }}> Y_Cap = (Y subject to Minimum and Minimum cap) Originator's share =</span>
                    <span className='text-primary' style={{ fontSize: "13px" }}> Y_Cap = (Y_cap - Admin fee - Processing fee)</span>
                    <span className='text-info' style={{ fontSize: "13px" }}> x (1- Payroll percentage) + Credit Report fee + Adjustment</span>
                </div>
            </div>
            <div class="row align-items-start my-5">
                <div class="col-lg-4">
                    <label for="inputPassword" className=" col-form-label text-capitalize" style={{ fontSize: "13px", letterSpacing: "1px" }}>Explanation</label>
                </div>
                <div class="col-lg-8">
                    <span className='text-muted' style={{ fontSize: "13px" }}>Broker is paying LO 112.5 bps and the lead was generated by the LO (Company-generated percentage is 0%). Assume the Admin fee is $300, the Processing fee is $500, the Minimum Cap is $1,000 and the Maximum Cap is $3,000. If the loan amount is $400,000 and the lender-paid compensation is 1%, then the Compensation is $4,000. Broker will pay LO $2200 = ($3,000- $300 admin fee - $500 processing fee)</span>
                </div>
            </div>
            <div class="row align-items-start mt-5">
                <div class="col-lg-4">
                </div>
                <div class="col-lg-8">
                    <span className='text-muted' style={{ fontSize: "13px" }}>Broker is paying LO 87.5 bps and the lead was generated by the LO (Company-generated percentage is 0%). Assume the Admin fee is $300, the Processing fee is $500, the Minimum Cap is $1,000 and the Maximum Cap is $3,000. If the loan amount is $70,000 and the lender-paid compensation is 1%, then the Compensation is $700. Broker will pay LO $200 = ($1,000- $300 admin fee-$500 processing fee)</span>
                </div>
            </div>
            <div class="row align-items-start mt-3">
                <div class="col-lg-4 mb-2 px-2">
                    <label for="inputPassword" className=" col-form-label text-capitalize ">Explanation</label>
                </div>
                <div class="col-lg-2 mb-2 px-2">
                    <label for="inputPassword" className=" col-form-label text-capitalize ">States</label>
                </div>
                <div class="col-lg-2 px-2">
                    <label for="inputPassword" className=" col-form-label text-capitalize">Compensation percent % (e.g. 1.25 for 1.25%)</label>
                </div>
                <div class="col-lg-2 px-2">
                    <label for="inputPassword" className=" col-form-label text-capitalize">Min Compensation</label>
                </div>
                <div class="col-lg-2 px-2">
                    <label for="inputPassword" className=" col-form-label text-capitalize">Max Compensation</label>
                </div>
                <hr />
            </div>
            <div class="row align-items-start my-5">
                <div class="col-lg-4 mb-2 px-2">
                </div>
                <div class="col-lg-2 mb-2 px-2">
                    <div class="input-group">
                        <input type="text" class="form-control rounded-0" placeholder="All" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div class="col-lg-2 px-2">
                    <div class="input-group">
                        <input type="text" class="form-control rounded-0" placeholder="1" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div class="col-lg-2 px-2 mt-2">
                    <div class="input-group">
                        <span class="input-group-text rounded-0" id="basic-addon1">$</span>
                        <input type="text" class="form-control rounded-0" placeholder="1.250" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div class="col-lg-2 px-2 mt-2">
                    <div class="input-group">
                        <span class="input-group-text rounded-0" id="basic-addon1">$</span>
                        <input type="text" class="form-control rounded-0" placeholder="4.00" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                </div>
            </div>

        </>
    )
}
