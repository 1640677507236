import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const BtnLoader = () => {
  return (
    <>
<ColorRing
  visible={true}
  height="20"
  width="20"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={['#fff', '#fff', '#fff', '#fff', '#fff']}
  />
    </>
  )
}

export default BtnLoader


